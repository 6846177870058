export default {
  data() {
    return {
      selectedActionName: null,
    };
  },
  computed: {
    buttonName() {
      const buttonName = this.allowedActions.find(data => {
        if (Array.isArray(this.actionName)) {
          for (let index = 0; index < this.actionName.length; index++) {
            let flag = this.checkAllowed(this.actionName[index].toUpperCase());
            if (flag) {
              return data.name === this.actionName[index];
            }
          }
          return false;
        } else {
          return data.name === this.actionName;
        }
      });
      if (buttonName) {
        return buttonName.button_name;
      } else {
        return '';
      }
    },
    isAllowed() {
      if (!this.applicationUuid) {
        return false;
      }
      if (Array.isArray(this.actionName)) {
        for (let index = 0; index < this.actionName.length; index++) {
          let flag = this.checkAllowed(this.actionName[index].toUpperCase());
          if (flag) {
            this.selectedActionName = this.actionName[index];
            return true;
          }
        }
        this.selectedActionName = null;
        return false;
      } else {
        this.selectedActionName = this.actionName;
        return this.checkAllowed(this.actionName.toUpperCase());
      }
    },
  },
  methods: {
    checkAllowed(actionName) {
      const applicationType = this.type.toUpperCase();
      const actionType = this.actionType ? this.actionType.toUpperCase() : null;
      let allowedActions = [];
      if (this.allowedActions) {
        allowedActions = this.allowedActions.map(elem => {
          return elem.name.toUpperCase();
        });
      }
      if (!actionType || applicationType === actionType) {
        return allowedActions.indexOf(actionName) !== -1;
      } else {
        return !actionType && allowedActions.indexOf(actionName) !== -1;
      }
    },
  },
};
